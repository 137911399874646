import React, { useState } from "react";

const PageContext = React.createContext({
  menuOpen: false,
  toggleMenu: (_?: string) => {}
});

enum ToggleStates {
  OPEN = "open",
  CLOSED = "closed"
}

const ContextProvider: React.FC = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = (setting?: string): void => {
    if (setting == ToggleStates.OPEN) {
      setMenuOpen(true);
    } else if (setting == ToggleStates.CLOSED) {
      setMenuOpen(false);
    } else {
      setMenuOpen(!menuOpen);
    }
  };

  return (
    <PageContext.Provider
      value={{
        menuOpen,
        toggleMenu
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageContext;

export { ContextProvider };
