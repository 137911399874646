/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react";
import { ContextProvider } from "./src/context/pageContext";
import FontFaceObserver from "fontfaceobserver";
import "./node_modules/normalize.css/normalize.css";
import "./src/styles/index.scss";


export const onClientEntry = () => {
  const notoSerifObserver = new FontFaceObserver("Noto Serif");
  const robotoObserver = new FontFaceObserver("Roboto");

  Promise.all([notoSerifObserver.load(), robotoObserver.load()]).then(
    () => (document.documentElement.className += " fonts-loaded")
  );
};

export const wrapRootElement = ({ element }) => (
  <ContextProvider>{element}</ContextProvider>
);
