// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-2020-07-12-the-start-of-something-new-mdx": () => import("./../../../src/pages/posts/2020-07-12-the-start-of-something-new.mdx" /* webpackChunkName: "component---src-pages-posts-2020-07-12-the-start-of-something-new-mdx" */),
  "component---src-pages-posts-2020-09-06-welcome-to-toronto-mdx": () => import("./../../../src/pages/posts/2020-09-06-welcome-to-toronto.mdx" /* webpackChunkName: "component---src-pages-posts-2020-09-06-welcome-to-toronto-mdx" */),
  "component---src-pages-posts-2021-03-24-updated-website-mdx": () => import("./../../../src/pages/posts/2021-03-24-updated-website.mdx" /* webpackChunkName: "component---src-pages-posts-2021-03-24-updated-website-mdx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

